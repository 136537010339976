import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InvoiceLoader from "./InvoiceLoader";
import GenerateReport from "./GenerateReport";
import SpyLoader from "./SpyLoader";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';


const muiTheme = () => {
  createMuiTheme({
    stepper: {
      iconColor: 'green'
    }
  })
}


const steps = ['Upload Catic Invoice', 'Upload Tracking File', 'Generate Formal Invoice'];

export default function HorizontalLinearStepper({ setSuccess }) {
  const [invoiceData, setInvoiceData] = React.useState({});
  const [invoiceTotal, setInvoiceTotal] = React.useState({});
  const [spyData, setSpyData] = React.useState({});
  const [month, setMonth] = React.useState({});
  const [successStep, setSuccessStep] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());


  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    setSuccessStep(false);
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <div style={{ width: '50%', float: 'canter', margin: '10%', paddingLeft: '10%' }} >
              <InvoiceLoader setInvoiceData={setInvoiceData} setMonth={setMonth} setInvoiceTotal={setInvoiceTotal} setSuccessStep={setSuccessStep} > </InvoiceLoader>
            </div>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <div style={{ width: '50%', float: 'canter', margin: '10%', paddingLeft: '10%' }} >
              <SpyLoader setSpyData={setSpyData} setSuccessStep={setSuccessStep}  > </SpyLoader>
            </div>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <div style={{ width: '50%', float: 'canter', margin: '10%', paddingLeft: '10%' }} >
              <GenerateReport invoiceData={invoiceData} month={month} spyData={spyData} invoiceTotal={invoiceTotal} setSuccess={setSuccess}> </GenerateReport>
            </div>
          </React.Fragment>)
      default:
        return "unknown step";
    }
  }

  return (
    <Box sx={{ width: '100%', align: 'center' }}>
      <MuiThemeProvider muiTheme={muiTheme}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel  {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </MuiThemeProvider>

      {activeStep === 2 ? (
        <React.Fragment>
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
          <React.Fragment>
            {getStepContent(activeStep)}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
            </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
              </Button>
              )}
              <Tooltip title="You must upload a valid file. " arrow disableHoverListener={successStep}>
                <span>
                  <Button onClick={handleNext} disabled={!successStep}>
                    {'Next'}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </React.Fragment>
        )}
    </Box>
  );
}