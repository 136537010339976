import React, { useState, useReducer } from "react";
import XLSX from "xlsx";
import { readFile } from "../../Utils";
import Alert from "../Alert";
import FabUploadFile from "../UploadFileButton";
import { CircularProgress, Backdrop } from "@material-ui/core";
import { useStyles } from "../../Styles";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';



export default ({ setSpyData, setSuccessStep }) => {
  const classes = useStyles({});
  const [alertData, setAlertData] = useState({
    open: true,
    message: null,
    title: "Error",
    severity: "error"
  });

  const setAlertMessage = (description) => {
    setAlertData({
      open: true,
      title: "Error",
      severity: "error",
      message: <>
        {description}
      </>
    });
  }

  const setSuccessMessage = (description) => {
    setAlertData({
      open: true,
      title: "Success",
      severity: "success",
      message: <>
        {description}
      </>
    });
  }


  const initialState = {
    loading: false,
    error: false,
    fileName: ' ',
    successUploading: false,
  };

  const [state, ChangeState] = useReducer(reducer, initialState);


  function reducer(state, action) {
    switch (action.isValid) {
      case false: {
        setAlertMessage(action.message)
        return { loading: false, error: true, fileName: ' ' };
      }
      case true:
        setSuccessMessage(action.message)
        return { loading: false, error: false, fileName: action.fileName, successUploading: true };
      case "restart":
        setAlertData({ open: false })
        return { loading: true, error: false, fileName: ' ', successUploading: false };
      case "finish":
        setAlertData({ open: false })
        return { loading: false, error: false, fileName: state.fileName };
      default:
        throw new Error();
    }
  }



  const isValidFileType = (files) => {
    try {
      var extension = files[0].name.substring(files[0].name.lastIndexOf('.'));
      if (extension != '.xlsx') {
        ChangeState({ isValid: false, message: "The file format is not supported" })
        return false
      }
      return true;
    }
    catch (e) {
      console.log(e)
      ChangeState({ isValid: false, message: "The file format is not supported" })
      return false;
    }
  }


  const isValidTemplate = (sheet) => {
    try {
      if (sheet['A1'].v != 'File Number' || sheet['B1'].v != 'Category') {
        ChangeState({ isValid: false, message: "Incorrect template. " })
        return false
      }
      return true;
    }
    catch (e) {
      console.log(e)
      ChangeState({ isValid: false, message: "Incorrect template. " })
      return false;
    }
  }

  const upload = (files) => {
    try {
      setSuccessStep(false)
      ChangeState({ isValid: 'restart' })
      const fileName = files[0].name;

      //Validate the file extenstion.
      if (!isValidFileType(files)) return;

      //reading the uploaded file as buffer . 
      readFile(files[0]).then((buffer) => {
        const workbook = XLSX.read(buffer, { type: "array" });
       // Object.values(workbook.Sheets).forEach((sheet) => {
         const sheet =  Object.values(workbook.Sheets)[0];
          const edge = sheet["!ref"].split(":")[1]; // get the last cell in sheet

          //Verify the file template is correct. 
          if (!isValidTemplate(sheet)) return;

          //create json of excel data. 
          const excelData = XLSX.utils.sheet_to_json(sheet, {
            range: `A1:${edge}`, raw: false
          })

          //mapping the data
          const data = excelData.map(x => {

            const getCategory = (cat) => {
              switch (cat) {
                case 'Us':
                  return 'J. Pando';
                case 'Not us':
                  return 'Catic';
                default:
                  return 'Unknown';
              }
            }

            return {
              "fileNumber": x["File Number"],
              "category": getCategory(x["Category"])
            }
          })
            .filter(x => x.category !== 'Not applicable' && x.category !== undefined)

          ChangeState({ isValid: true, message: "Your file uploaded successfuly.", fileName: fileName })
          setSpyData(data)
          setSuccessStep(true)
      });
    }
    catch (e) {
      console.log(e)
      ChangeState({ isValid: false, message: "Something went wrong.\n Try again.\n" + e })
    }
  };

  return (
    <>
      <Box>
        <Typography gutterBottom="true" sx={{ color: "#FFA500", p: 2, border: 1, borderColor: 'primary.main', borderRadius: '16px' }} variant="body1">Note: The tracking file month must match the invoice month.</Typography>
        <FabUploadFile
          accept=".xls,.xlsx"
          upload={upload}
          text="Upload Tracking File"
          required />
        <p display={state.successUploading} style={{ color: "green" }}>{state.fileName}</p>
      </Box>
      {state.error &&
        <Alert
          alertData={alertData}
          closeModal={() => { ChangeState({ isValid: 'finish' }) }}
        />}
      {state.loading &&
        <Backdrop className={classes.backdrop} open={state.loading}>
          <CircularProgress disableShrink={true} color="inherit" />
        </Backdrop>}
      {state.successUploading &&
        <Alert
          alertData={alertData}
          closeModal={() => { ChangeState({ isValid: 'finish' }) }}
        />}
    </>
  );
};