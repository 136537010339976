import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { currentDate } from "../../Utils";

//TODO:  change these functions to generic (instead of dedicated to CATIC requirments. )


//This function build CATIC invoice and download it. 
export const buildInvoice = (invoiceData, invoicePeriod, invoiceTotal, parentId) => {
    try {
        var doc = new jsPDF({ orientation: 'l' }); //define landscape page
        //Draw the table
        doc.autoTable({
            margin: { top: 5 },
            startY: 110,
            theme: 'plain',
            head: [["File Number", "County", "Order Received", "Bulk Charge", "Extra Time", "Copies", "Heavy Search", "Misc", "Total Invoice Amount", "Comments"]],
            body: getTableRaws(invoiceData, invoiceTotal),
            styles: {
                lineColor: [44, 62, 80],
                lineWidth: 0.2,
            },
            headStyles: {
                fillColor: [220, 220, 220],
                fontSize: 13,
                fontStyle: 'bold'
            },
            footStyles: {
                fillColor: [241, 196, 15],
                fontSize: 13,
                fontStyle: 'bold'
            },
            didParseCell: function (data) {
                var rows = data.table.body;
                if (data.row.index === rows.length - 1 || data.row.index === rows.length - 2) {
                    data.cell.styles.fillColor = [220, 220, 220];
                    data.cell.styles.fontStyle = 'bold';
                }
            },
            didDrawPage: function (hookData) {
                //header of the page
                if (hookData.pageNumber == 1) {
                    doc.setFontSize(25)
                        .setFont("times", "bold")
                        .text("J. PANDO, LLC .", 30, 30);
                    doc.setTextColor(150)
                        .text("Invoice", 180, 30);
                    doc.setFontSize(14)
                        .setFont("Helvetica", "normal")
                        .setLineHeightFactor(1.25)
                        .setTextColor(0, 0, 0)
                        .text("1125 Ocean Avenue\nLakewood, NJ 08701 \n732.333.2273", 30, 45);

                    doc.text("Invoice #:", 180, 45);
                    doc.text(`CAT-${parentId}`, 224, 45);

                    doc.text("Date: ", 180, 51);
                    const d = currentDate.replaceAll('.', '/')
                    doc.text(`${d}`, 224, 51);

                    doc.text("Invoice Period: ", 180, 57);
                    doc.text(`${invoicePeriod}`, 224, 57);

                    doc.setFont("times", "bold")
                    doc.text("To:", 30, 70);
                    doc.setFont("Helvetica", "normal")
                    doc.text("CATIC Title Insurance Company\n660 White Plains Road, Suite 570\nTarrytown NY -10591", 30, 85);
                }

                //footer of the page
                doc.setFontSize(10);
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height
                    ? pageSize.height
                    : pageSize.getHeight();
                doc.text('J. PANDO, LLC. | 1125 Ocean Avenue. Lakewood NJ 08701 | 732.333.2273', doc.internal.pageSize.width / 2, pageHeight - 10, {
                    align: 'center'
                })
            },
        });


        var name = `CATIC_Invoice_${invoicePeriod}.pdf`
        doc.save(name) //download the file
        return new Blob([doc.output('blob')], { type: 'application/pdf' });
    }
    catch (e) {
        console.log(e);
    }
}


//Returns the content of the invoice table. 
const getOrdersRaws = (invoiceData) => {
    console.log("invoice data", invoiceData)
    return invoiceData.map(i => {
        if (i['fileNumber']) {
            return [
                i.fileNumber,
                i.county,
                formattedDate(i.receivedOn),
                formattedNumber(i.bulkCharge),
                formattedNumber(i.extraChargesTime),
                formattedNumber(i.extraChargesCopies),
                formattedNumber(i.heavySearch),
                formattedNumber(i.miscCharges),
                formattedNumber(i.totalAmount),
                i.comments
            ]
        }
    })
        .filter(i => { return i !== undefined })
        .map(i => i.map((el) => {
            if (el === '$ NaN')
                return " ";
            return el;
        }))
}

const getTableRaws = (invoiceData, invoiceTotal) => {
    const total =
        [
            "Total: ",
            " ",
            " ",
            formattedNumber(invoiceTotal.bulkCharge),
            formattedNumber(invoiceTotal.extrChargesTime),
            formattedNumber(invoiceTotal.extraChargesCopies),
            formattedNumber(invoiceTotal.heavySearch),
            formattedNumber(invoiceTotal.miscCharges),
            formattedNumber(invoiceTotal.totalInvoiceAmount),
        ];
    const tableRaws = getOrdersRaws(invoiceData)
    tableRaws.push(total); //sum of each one of the columns.
    tableRaws.push([" ", " ", " ", " ", " ", " ", " ", formattedNumber(invoiceTotal.totalInvoiceAmount)]) //total of all the invoice charges (last raw in the table. )
    return tableRaws;
}

const formattedNumber = (str) => {
    if (str == undefined)
        return ' ';
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const formattedNum = formatter.format(parseFloat(str))
    console.log("formattedNum", typeof formattedNum)
    return formattedNum !== '$NaN' ? formattedNum : str;
}

const formattedDate = (str) => {
    if (str !== undefined) {
        var dateArr = str.split('-');
        return (dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0]);
    }
    else
        return ' ';
}

