import React, { useState } from "react";
import Confetti from "react-dom-confetti";
import GenerateWizard from "./Wizard";

export default () => {
  const [success, setSuccess] = useState(false);

  const config = {
    angle: "258",
    spread: 360,
    startVelocity: 40,
    elementCount: "200",
    dragFriction: "0.05",
    duration: "7010",
    stagger: "2",
    width: "10px",
    height: "10px",
    perspective: "1000px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  return (
    <>
      <div style={{
        position: 'absolute',
        top: '30%',
        left: '30%',
        width: '40%',
        height: '60%'
      }} >
        {!success ? (
          <GenerateWizard setSuccess={setSuccess}>   </GenerateWizard>) : null}
      <Confetti active={success} config={config} />
      {success ? (<React.Fragment>
        <h1 style={{ textAlign: 'center', top: "30%" }}><bold> Well Done! </bold></h1>
        <h2 style={{ textAlign: 'center', top: "30%" }}><bold> You finished to generate CATIC invoice :) </bold></h2>
        <p style={{  zIndex: '10000' , textAlign: 'center', top: '65%' }} ><a href="https://madisoncrestitle.monday.com/boards/2703525720" target="_blank" style={{ color: 'black' }}>Go to CATIC invoices board</a></p>
      </React.Fragment>) : null}
      </div>
    </>
  );
};