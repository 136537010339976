import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tabs from "./Tabs";
import { authContext } from '../adalConfig';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

export default () => {
  console.log(authContext)
  console.log(authContext.getCachedUser())

  const classes = useStyles();
  const [profile] = useState(authContext.getCachedUser().profile);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Title Invoices Generator
          </Typography>
          <Avatar onClick={handleMenu}>{profile.given_name.charAt(0) + profile.family_name.charAt(0)}</Avatar>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => authContext.logOut()}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Tabs></Tabs>
    </div>
  );
};