import mondaySdk from "monday-sdk-js";

export const uploadAsset =  async (blob, assetName, itemId, columnId) => {
    try {
        const formData = new FormData();
        formData.append("variables[file]", blob, assetName);
        formData.append("query", uploadFileQuery(itemId,columnId ));
        await fetch(process.env.REACT_APP_MONDAY_API_URL, {
            method: "POST",
            body: formData,
            headers: {
                "Authorization": process.env.REACT_APP_MONDAY_TOKEN
            }
        })
    }
    catch (e) {
        console.log(e);
        throw e;
    }
}

export const createItem = async (boardId, itemName, columnVal)=>{
    try {
        console.log("start to create item")
        const mondayClient = mondaySdk();
        mondayClient.setToken(process.env.REACT_APP_MONDAY_TOKEN);
        const variables = { boardId, itemName, columnVal };
        mondayClient.api(createItemQuery(), { variables }).then(res=>{
            if(!res.OK){
                return false;
            }
            return true;
        })
    }
    catch (e) {
        console.log(e)
        return false;
    }
}

const uploadFileQuery = (itemId, columnId) => `mutation addFile($file: File!) {
    add_file_to_column (item_id: ${itemId}, column_id: ${columnId.toString()} , file: $file) {
      id
    }
  }`;

  const createItemQuery = () => `mutation create_item($boardId:Int!, $itemName: String!, $columnVal: JSON! ) {
    create_item (board_id: $boardId, item_name: $itemName, column_values: $columnVal ) {id
    }
  }`;
  
