import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const defaultAlertData = {
  isOpen: false,
  message: "",
  severity: "info",
  title: "",
};

export default ({ alertData, closeModal }) => {
  const classes = useStyles();
  const [alert, setAlert] = useState(defaultAlertData);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    closeModal();
    setAlert(defaultAlertData);
  };

  useEffect(() => {
    setAlert(alertData);
  }, [alertData]);

  return (
    <div className={classes.root}>
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.severity}>
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};