import React, { useState, useReducer } from "react";
import { Fab, CircularProgress, Backdrop } from "@material-ui/core";
import { useStyles } from "../../Styles";
import mondaySdk from "monday-sdk-js";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { buildInvoice } from "./GenerateFormalInvoice"
import { isEmpty } from "./../../Utils"
import { uploadAsset, createItem } from './../../mondayUtils'
import Alert from "../Alert";


//TODO
const createInvoice = `mutation create_item($itemName: String!, $columnVal: JSON! ) { 
  create_item (board_id: 2703525720, item_name: $itemName, column_values: $columnVal ) {id
  }
}`;

export default ({ invoiceData, month, spyData, invoiceTotal, setSuccess }) => {
    const classes = useStyles({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [alertData, setAlertData] = useState({
        open: true,
        message: null,
        title: "Error",
        severity: "error"
    });

    const setAlertMessage = (description) => {
        setAlertData({
            open: true,
            title: "Error",
            severity: "error",
            message: <>
                {description}
            </>
        });
    }

    const initialState = {
        loading: false,
        error: false,
    };

    const [state, ChangeState] = useReducer(reducer, initialState);


    function reducer(state, action) {
        switch (action.status) {
            case "error": {
                setAlertMessage(action.message)
                return { loading: false, error: true };
            }
            case "restart": {
                return { loading: true, error: false, };
            }
            case "finish":
                {
                    setAlertData({ open: false })
                    return { loading: false, error: false };
                }
            default:
                throw new Error();
        }
    }


    async function createParentLineInMonday(month, invoiceAmount) {
        try {
            const mondayClient = mondaySdk();
            mondayClient.setToken(process.env.REACT_APP_MONDAY_TOKEN);
            var d = month.split("/");
            const columnVal = JSON.stringify({ "text": month, "text7": d[1] + "/" + d[0], "numbers": invoiceAmount });
            var itemName = "catic-invoice-" + month;
            const variables = { itemName, columnVal };
            const parentId = mondayClient.api(createInvoice, { variables }).then(res => {
                if (res.data.create_item.id === undefined) {
                    ChangeState({ status: 'error', message: "Something doesn't work, Check your connection and try again." })
                    return false;
                }
                return res.data.create_item.id
            })
            return parentId;
        }
        catch (e) {
            console.log(e)
            ChangeState({ status: 'error', message: "Something doesn't work, Check your connection and try again." })
            return false;
        }
    }

    //sending the orders to the the server, and the server will save it to monday. 
    const saveOrdersToMonday = async (parentId, invoiceData) => {
        console.log("Sending orders to server in order to create them in monday.com (as subitems)")
        const subitems = invoiceData.map(x => {
            return {
                "name": x.fileNumber,
                "columnVals": {
                    "text8": x.county,
                    "numbers": x.bulkCharge,
                    "numbers8": x.extraChargesTime,
                    "numbers4": x.miscCharges,
                    "numbers1": x.extraChargesCopies,
                    "numbers80": x.heavySearch,
                    "numbers40": x.totalAmount,
                    "text4": x.comments,
                    "date0": { "date": x.receivedOn }
                }
            }
        });
        console.log("saveOrdersToMonday:  Orders are: ", subitems)
        const res = fetch(process.env.REACT_APP_CREATE_MULTIPLAE_SUBITEMS_API, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "parentId": parentId, "subitems": subitems })
        })
            .then(res => {
                console.log("saveOrdersToMonday:  res: ", res)
                if (res.status == 202){
                    console.log("status is 202")
                    return true;}
                else{
                    throw "Something went wrong";
                }
            })
            .catch(e => {
                console.log("saveOrdersToMonday:  catched error: ", e)
                ChangeState({ status: 'error', message: "Something doesn't work, Check your connection and try again." })
                return false;
            })
            return res;
    }



    const SaveSpyToMonday = async (spyData, month) => {
    /*  //sending from  client 
        try {
            console.log("SaveSpyToMonday: Start to save the spy data to monday.com")

            spyData.forEach(x => {
                const columnVal = JSON.stringify({ "text1": month, "dropdown": x.category });
                createItem(parseInt(process.env.REACT_APP_CATIC_SPY_BOARD_ID), x.fileNumber, columnVal);
                return true;
            })
        }
        catch (e) {
            ChangeState({ status: 'error', message: "Something doesn't work, Check your connection and try again." })
            return false;
        }*/

        //sending from server
        const data = spyData.map(x => {
            var d = month.split("/");
            //const columnVal = JSON.stringify({ "text1": month, "dropdown": x.category });
            return ({"boardId":parseInt(process.env.REACT_APP_CATIC_SPY_BOARD_ID),
                        "itemName": x.fileNumber, 
                        "columnVals": { "text1": month, "dup__of_month2": d[1] + "/" + d[0], "dropdown": x.category }});

        })
        console.log("saveSpyToMonday:  Spy  Data: ", data)

        const res = fetch(process.env.REACT_APP_CREATE_MULTIPLAE_ITEMS_API, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "data": data })
        })
            .then(res => {
                console.log("saveSpyToMonday:  res: ", res)
                if (res.status == 202){
                    return true;}
                else{
                    throw "Something went wrong";
                }
            })
            .catch(e => {
                console.log("saveOrdersToMonday:  catched error: ", e)
                ChangeState({ status: 'error', message: "Something doesn't work, Check your connection and try again." })
                return false;
            })
            return res;



    }

    async function download() {
        try {
            console.log("User clicked download.")
            ChangeState({ status: 'restart' })
            const parentId = await createParentLineInMonday(month, invoiceTotal.totalInvoiceAmount).then(res => {
                if (!res) return;
                else return res;
            })
            console.log("Finish to creat the invoice parent. parentId: ", parentId)

            if (! await saveOrdersToMonday(parentId, invoiceData)) { return; }
            console.log("download: finish to send orders to server, starting to send spy data to monday")

            if (! await SaveSpyToMonday(spyData, month)) { return; }
            console.log("download: finish to send spy to server, starting to generate  the invoice PDF")

            const invoice = buildInvoice(invoiceData, month, invoiceTotal, parentId);
            uploadAsset(invoice, 'Catic_Invoice ' + month.replace('/','_') + '.pdf', parentId, 'files') //TODO: Change the name of the file. 
            setLoading(false);
            setSuccess(true);
        }
        catch (e) {
            console.log(e)
            ChangeState({ status: 'error', message: e.message })
        }
    };

    return (
        <div>
            <Fab
                disabled={isEmpty(invoiceData)}
                display="block"
                color="primary"
                aria-label="Upload"
                variant="extended"
                className={classes.fab}
                onClick={download}
            >
                <CloudDownloadIcon className={classes.fabIcon} />
                Download Formal Invoice
            </Fab>
            {state.loading &&
                <Backdrop className={classes.backdrop} open={state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>}
            {state.error &&
                <Alert
                    alertData={alertData}
                    closeModal={() => { ChangeState({ status: 'finish' }) }}
                />}
        </div>
    )
};
