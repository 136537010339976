import React, { useState, useReducer  } from "react";
import FabUploadFile from "../UploadFileButton";
import XLSX from "xlsx";
import { readFile, excelDateToISODateString } from "../../Utils";
import Alert from "../Alert";
import { CircularProgress, Backdrop } from "@material-ui/core";
import { useStyles } from "../../Styles";
import Box from '@mui/material/Box';
import mondaySdk from "monday-sdk-js";



export default ({ setInvoiceData, setMonth, setInvoiceTotal, setSuccessStep }) => {
  const classes = useStyles({});
  const [alertData, setAlertData] = useState({
    open: true,
    message: null,
    title: "Error",
    severity: "error"
  });

  const setAlertMessage = (description) => {
    setAlertData({
      open: true,
      title: "Error",
      severity: "error",
      message: <>
        {description}
      </>
    });
  }

  const setSuccessMessage = (description) => {
    setAlertData({
      open: true,
      title: "Success",
      severity: "success",
      message: <>
        {description}
      </>
    });
  }


  const initialState = {
    loading: false, 
    error: false,
    fileName: ' ',
    successUploading: false,
  };

  const [state, ChangeState] = useReducer(reducer, initialState);


  function reducer(state, action) {
    switch (action.isValid) {
      case false:{
        setAlertMessage(action.message)
        setSuccessStep(false)
        return {loading: false,error: true,fileName: ' ',  successUploading: false};
      }
      case true:
        setSuccessMessage(action.message)
        return {loading: false, error: false ,fileName: action.fileName , successUploading: true};
        case "restart":
          setAlertData({open: false})
          return {loading: true ,error: false, fileName: ' ',  successUploading: false};
          case "finish":
            setAlertData({open: false})
            return {loading: false ,error: false, successUploading: true ,  fileName: state.fileName};
      default:
        throw new Error();
  }
}


  const getInvoiceByMonth = (invoicePeriod)=>{
    return `query {
      items_by_column_values  (board_id: ${parseInt(process.env.REACT_APP_CATIC_INVOICES_BOARD_ID)}, column_id: "text", column_value: "${invoicePeriod}") {
          id, 
          state
      }
  }`
  }

  //TODO: Change the function to generic function. 
  const isNewInvoice= async (invoicePeriod) => {
    try{
          const mondayClient = mondaySdk();
          mondayClient.setToken(process.env.REACT_APP_MONDAY_TOKEN);
          var res = await mondayClient.api(getInvoiceByMonth(invoicePeriod));
          if (res.data.items_by_column_values[0] !== undefined && res.data.items_by_column_values.map(x=>x.state).includes("active") ){ //there is an invoice with same date. 
            ChangeState({isValid: false, message: "Invoice on this month was generated already. "})
            return false;
          }
          return true
      }
      catch (e) {
        ChangeState({isValid: false, message: "Invoice on this month was generated already. "})
        return false; 
      }
}



  const isValidTemplate = (sheet) => {
    try {
      if (sheet['B9'].v != 'File Number:' ||
        sheet['C9'].v != 'transaction' ||
        sheet['D9'].v != 'County:' ||
        sheet['E9'].v != 'Order Received' ||
        sheet['F9'].v != 'Bulk Charge' ||
        sheet['G9'].v != 'Extra Time' ||
        sheet['H9'].v != 'Copies' ||
        sheet['I9'].v != 'Heavy Search' ||
        sheet['J9'].v != 'Misc' ||
        sheet['K9'].v != 'Total Invoice Amount' ||
        sheet['M9'].v != 'Comments:' //|| 
        // sheet['B7'].t != 'n'
      ) {
        ChangeState({isValid: false, message: "Incorrect template. "})
        return false
      }
      return true;
    }
    catch (e) {
      console.log(e)
      ChangeState({isValid: false, message: "Incorrect template. "})
      return false;
    }
  }
  const isValidDates = (data) => {
    try {
      const invalidDates = data.filter(i => {
        return isNaN(i["Order Received"])
      })
      .filter(i=>{
        return i["Order Received"] !== undefined
      })
      console.log("invalidDates:", invalidDates)
      if (invalidDates.length == 1) {
        ChangeState({isValid: false, message: "Incorrect Date: " + invalidDates[0]["Order Received"]})
        return false
      }
      if (invalidDates.length > 1) {
        ChangeState({isValid: false, message: "Incorrect Dates: " + invalidDates.map(x=>{return x["Order Received"]} )}  )
        return false
      }
      return true;

    }
    catch (e) {
      console.log(e)
      ChangeState({isValid: false, message: "Oppps, Something went wrong"})
      return false
    }
  }

  const isValidFileType = (files) => {
    try {
      var extension = files[0].name.substring(files[0].name.lastIndexOf('.'));
      if (extension != '.xlsx') {
        ChangeState({isValid: false, message: "The file format is not supported"})
        return false
      }
      return true;
    }
    catch (e) {
      console.log(e)
      ChangeState({isValid: false, message: "The file format is not supported"})
      return false; 
    }
  }

  //This function read the data from the excel file that was uploaded,  convert the data to json object and returns it.
  const upload =  (files) => {
    try {
      ChangeState({isValid: 'restart'})
      if (!isValidFileType(files)) return;
      const fileName= files[0].name;

      
      //reading the uploaded file as buffer . 
      readFile(files[0]).then((buffer) => {
        const workbook = XLSX.read(buffer, { type: "array", cellText: false });
        Object.values(workbook.Sheets).forEach((sheet) => {
          const edge = 'M'.concat(sheet["!ref"].replace(/[^\d-]/g, '')) ;
          if (!isValidTemplate(sheet)) return;

          //create json from the excel .
          const excelData = XLSX.utils.sheet_to_json(sheet, {
            range: `B9:${edge}`, raw: false
          })

          
          //remove empty lines
          const invoiceData = excelData.filter(x => {
            return x["File Number:"] !== undefined;
          })

          //Verify all the dates are valid.
          if (!isValidDates(invoiceData)) return;

          //mapping the data
          const orders = invoiceData.map(i => {
            return {
              "fileNumber": i["File Number:"],
              "county": i["County:"],
              "bulkCharge": i["Bulk Charge"],
              "totalAmount": i["Total Invoice Amount"],
              "extraChargesTime": i["Extra Time"],
              "extraChargesCopies": i["Copies"],
              "heavySearch": i["Heavy Search"],
              "comments": i["Comments:"],
              "miscCharges": i["Misc"],
              "receivedOn": excelDateToISODateString(i["Order Received"])
            }
          });

          //mapping the total line (TODO: Calculate the total in the code instead of reading the total from the excel)
          const invoiceTotal = {
            "orderNumber": ' ',
            "county": ' ',
            "receivedOn": ' ',
            "bulkCharge": excelData[excelData.length - 2]["Bulk Charge"].toString(),
            "extraChargesCopies": excelData[excelData.length - 2]["Copies"].toString(),
            "heavySearch": excelData[excelData.length - 2]["Heavy Search"].toString(),
            "extrChargesTime": excelData[excelData.length - 2]["Extra Time"].toString(),
            "miscCharges": excelData[excelData.length - 2]["Misc"].toString(),
            "totalInvoiceAmount": excelData[excelData.length - 2]["Total Invoice Amount"].toString(),
          }

          //validate the invoice period from the excel is valid.
          if (isNaN(sheet['B7'].v)) {
            ChangeState({isValid: false, message: "Incorrect Date: " + sheet['B7'].v})
            return;
          }; 

          //read the month of the invoice and format it. 
          console.log("start to read date")
          const d = excelDateToISODateString(sheet['B7'].v).split('-')  //extract the period of the invoice from the excel.
          const invoiceMonth=d[1]+'/'+ d[0];

          //Verify that no invoice on same month.
          isNewInvoice(invoiceMonth).then((isNew)=>{
            if (!isNew){
                return;
            }
            else {
              ChangeState({isValid: true, message: "Your file uploaded successfuly.", fileName: fileName })
              setInvoiceData(orders);
              setMonth(invoiceMonth);
              setInvoiceTotal(invoiceTotal)
              setSuccessStep(true)
            }
          })
        });
      });
    }
    catch (e) {
      console.log(e)
      ChangeState({isValid: false, message: "Something went wrong.\n Try again.\n"+e})
    }
  }

  return (
    <>
     <Box>
      <FabUploadFile
        accept=".xls,.xlsx"
        upload={upload}
        text="Upload Catic Invoice"
        required />
        <p display={state.successUploading} style={{color:"green"}}>{state.fileName}</p>

        </Box>
      {state.loading &&
        <Backdrop className={classes.backdrop} invisable="true" open={state.loading}>
          <CircularProgress disableShrink={true} color="inherit" />
        </Backdrop>}
      {state.error &&
        <Alert
          alertData={alertData}
          closeModal={() => {ChangeState({isValid: 'finish',  })}}
        />}
      {state.successUploading &&
        <Alert
          alertData={alertData}
          closeModal={() => {ChangeState({isValid: 'finish' })}}
        />}
        

    </>
  );
}