import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Weichert from "./weichert/Weichert";
import Catic from "./catic/Catic";
import Vested from "./vested/Vested";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`
  };
}

function LinkTab(props) {
  return (
    <Tab
      component={Link}
      to={props.pathname}
      {...props}
    />
  );
}

/*
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  }
}));*/

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const currentTab = () => {
  let path = window.location.pathname
  if (path === "/catic") return 1;
  else if (path === "/vested") return 2;
  else return 0;
}

export default function NavTabs() {

  const classes = useStyles();
  const [value, setValue] = useState(currentTab());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <BrowserRouter>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

          <Tabs
            value={value}
            onChange={handleChange}
            //   aria-label="nav tabs example"
            // indicatorColor="primary"
            textColor="primary"
            centered
          >
            <LinkTab label="Weichert" pathname="/weichert" {...a11yProps(0)} />
            <LinkTab label="Catic" pathname="/catic" {...a11yProps(1)} />
            <LinkTab label="Vested" pathname="/vested" {...a11yProps(2)} />

          </Tabs>
        </Box>

        <Routes>
          <Route path="/weichert" element={<Weichert />} ></Route>
          <Route path="/catic" element={<Catic />} ></Route>
          <Route path="/vested" element={<Vested />} ></Route>
          <Route path="*" element={<Weichert />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}